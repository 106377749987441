@font-face {
  font-family: "Nanum Gothic";
  src: url("../../public/assets/fonts/Nanum_Gothic/NanumGothic-Regular.ttf");
}
@font-face {
  font-family: "Nanum Gothic Bold";
  src: url("../../public/assets/fonts/Nanum_Gothic/NanumGothic-Bold.ttf");
}
@font-face {
  font-family: "Gothic A1";
  src: url("../../public/assets/fonts/GothicA1/GothicA1-Regular.ttf");
}
@font-face {
  font-family: "NotoSans";
  src: url("../../public/assets/fonts/NotoSans/NotoSansKR-Regular.otf");
}
@font-face {
  font-family: "NotoSans bold";
  src: url("../../public/assets/fonts/NotoSans/NotoSansKR-Bold.otf");
}
@font-face {
  font-family: "SCDream";
  src: url("../../public/assets/fonts/SCDream/SCDream6.otf");
}
* {
  margin: 0;
  padding: 0; 
}
*::-webkit-scrollbar {
  width: 0.5vw;
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: lightgray;
  min-height: 5vh;
}

body {
  overflow: hidden;
  margin: 0;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  
}
body #root {
  flex: 1;
}

@media (max-width: 1280px) {
  body {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=core.css.map */